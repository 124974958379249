import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { medWrapper, headlineOne, colors } from "../Utilities"

const LogosRowsSection = styled.section`
  padding: 5rem 0;
  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .logo {
    width: calc(50% - 2rem);
    margin: 1rem;

    @media (min-width: 768px) {
      width: calc(25% - 3rem);
      margin: 1.5rem;
    }

    @media (min-width: 1025px) {
      width: calc(20% - 4rem);
      margin: 2rem;
    }
  }
`

const LogosRows = ({ title, logos }) => {
  return (
    <LogosRowsSection>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        {logos.map((logo, index) => {
          return (
            <a className="logo" href={logo.url_link} key={index}>
              <Img
                fluid={logo.logo.localFile.childImageSharp.fluid}
                alt={logo.logo.alt_text}
              />
            </a>
          )
        })}
      </div>
    </LogosRowsSection>
  )
}

export default LogosRows

import React from "react"
import styled from "styled-components"
import {
  medWrapper,
  headlineOne,
  colors,
  headlineThree,
  bodyCopy,
} from "../Utilities"

const CoreValuesSection = styled.section`
  background: rgba(205, 209, 210, 0.13);
  padding: 5rem 0;

  .wrapper {
    ${medWrapper};
    overflow: hidden;
  }

  .title {
    width: 100%;
    max-width: 85rem;
    margin: 0 auto 4rem;
    text-align: center;

    h2 {
      ${headlineOne};
      color: ${colors.colorPrimary};
    }
  }

  .coreValues {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const CoreItem = styled.div`
  width: calc(100%);
  margin: 2rem 0;

  @media (min-width: 768px) {
    width: calc(50%);
  }

  p {
    margin: 0;
  }

  p:first-of-type {
    ${headlineThree};
  }

  p:last-of-type {
    ${bodyCopy};
  }
`

const CoreValues = ({ title, values }) => {
  return (
    <CoreValuesSection>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        <div className="coreValues">
          {values.map((value, index) => {
            return (
              <CoreItem key={index}>
                <p>{value.title}</p>
                <p>{value.content}</p>
              </CoreItem>
            )
          })}
        </div>
      </div>
    </CoreValuesSection>
  )
}

export default CoreValues

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroImage from "../components/HeroImage"
import Intro from "../components/Intro"
import MemoryExperience from "../components/MemoryExperience"
import ImageBgBesideContent from "../components/ImageBgBesideContent"
import CoreValues from "../components/CoreValues"
import VisionMissionBlocks from "../components/VisionMissionBlocks"
import CalloutSection from "../components/CalloutSection"
import LogosRows from "../components/LogosRows"
import TestimonalSlider from "../components/TestimonalSlider"

const about = props => {
  const location = props.location.pathname
  const { acf } = props.data.page

  const metaTitle = acf._swb_theme_meta_title
  const metaDescription = acf._swb_theme_description
  const metaImage = acf._swb_theme_image.localFile.publicURL

  const heroImgFluid = acf._ikag_hero_image.localFile.childImageSharp.fluid

  const introTitle = acf._ikag_intro_title
  const introItalics = acf._ikag_intro_title_italic
  const introContent = acf._ikag_intro_content

  const meImgOneFluid = acf._ikag_me_image_one.localFile.childImageSharp.fluid
  const meImgOneAlt = acf._ikag_me_image_one.alt_text
  const meImgTwoFluid = acf._ikag_me_image_two.localFile.childImageSharp.fluid
  const meImgTwoAlt = acf._ikag_me_image_two.alt_text
  const meBtnReq = acf._ikag_me_req_con_btn

  const iBBCimgFluid = acf._ikag_ibbc_image.localFile.childImageSharp.fluid
  const iBBCimgAlt = acf._ikag_ibbc_image.alt_text
  const iBBCBgImgFluid =
    acf._ikag_ibbc_background_image.localFile.childImageSharp.fluid
  const iBBCTitle = acf._ikag_ibbc_title
  const iBBCContent = acf._ikag_ibbc_content

  const coreTitle = acf._ikag_core_title
  const coreValues = acf._ikag_core_values

  const visMisBlocks = acf._ikag_vm_blocks

  const cosTitleTop = acf._ikag_cos_title_top
  const cosTitleBot = acf._ikag_cos_title_italic
  const cosBtnText = acf._ikag_cos_button_text
  const cosBtnLink = acf._ikag_cos_button_link.post_name

  const logoRowsTitles = acf._ikag_lro_title
  const logoRowsLogs = acf._ikag_lro_logos

  const testDisplay = acf._ikag_testimonials_display

  return (
    <Layout location={location}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        metaImg={metaImage}
        location={props.location.pathname}
      />
      <HeroImage fluid={heroImgFluid} />
      <Intro title={introTitle} italic={introItalics} content={introContent} />
      <MemoryExperience
        imgOneFluid={meImgOneFluid}
        imgOneAlt={meImgOneAlt}
        imgTwoFluid={meImgTwoFluid}
        imgTwoAlt={meImgTwoAlt}
        btnReq={meBtnReq}
      />
      <ImageBgBesideContent
        imgFluid={iBBCimgFluid}
        imgAlt={iBBCimgAlt}
        bgFluid={iBBCBgImgFluid}
        title={iBBCTitle}
        content={iBBCContent}
      />
      <CoreValues title={coreTitle} values={coreValues} />
      <VisionMissionBlocks blocks={visMisBlocks} />
      <CalloutSection
        titleTop={cosTitleTop}
        titleBot={cosTitleBot}
        btnText={cosBtnText}
        btnLink={cosBtnLink}
      />
      <LogosRows title={logoRowsTitles} logos={logoRowsLogs} />
      {testDisplay === "yes" && <TestimonalSlider />}
    </Layout>
  )
}

export const homeQuery = graphql`
  query aboutPage($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
        _ikag_hero_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_intro_title
        _ikag_intro_title_italic
        _ikag_intro_content

        _ikag_me_image_one {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_me_image_two {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        _ikag_me_req_con_btn

        _ikag_ibbc_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_ibbc_background_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }

        _ikag_ibbc_title
        _ikag_ibbc_content

        _ikag_core_title
        _ikag_core_values {
          title
          content
        }

        _ikag_vm_blocks {
          side_title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }

        _ikag_lro_title
        _ikag_lro_logos {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          url_link
        }

        _ikag_cos_title_top
        _ikag_cos_title_italic
        _ikag_cos_button_text
        _ikag_cos_button_link {
          post_name
        }

        _ikag_testimonials_display
      }
    }
  }
`

export default about

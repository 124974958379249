import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { medWrapper, bodyCopy, headlineThree, colors } from "../Utilities"

const VMSection = styled.section`
  .wrapper {
    ${medWrapper};
    overflow: hidden;
  }
`

const VMBlock = styled.div`
  position: relative;
  width: calc(100%);
  margin: 5rem 0;

  @media (min-width: 768px) {
    width: calc(50% - 10rem);
    margin: 0 5rem;
  }

  .blockTitle {
    position: absolute;
    bottom: 35%;
    left: 2rem;
    width: 100%;
    transform-origin: left center;
    transform: rotate(-90deg);

    @media (min-width: 768px) {
      bottom: 45%;
      left: 2rem;
      width: 100%;
    }

    @media (min-width: 1025px) {
      bottom: 35%;
      left: 2rem;
      width: 100%;
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 25rem;
      height: 0.2rem;
      background: ${colors.colorSecondary};
      content: "";
    }

    h2 {
      ${headlineThree};
    }
  }

  .blockImg {
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;

    @media (min-width: 768px) {
      width: 17.5rem;
      height: 17.5rem;
    }

    @media (min-width: 900px) {
      width: 20rem;
      height: 20rem;
    }

    @media (min-width: 1025px) {
      width: 28rem;
      height: 28rem;
    }

    img {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .blockContent {
    margin-top: 5rem;
    text-align: center;

    p {
      ${bodyCopy};
    }
  }
`

const VisionMissionBlocks = ({ blocks }) => {
  return (
    <VMSection>
      <div className="wrapper">
        {blocks.length > 0 &&
          blocks.map((block, index) => {
            return (
              <VMBlock key={index}>
                <div className="blockTitle">
                  <h2>{block.side_title}</h2>
                </div>
                <div className="blockImg">
                  <Img
                    fluid={block.image.localFile.childImageSharp.fluid}
                    alt={block.image.alt_text}
                  />
                </div>
                <div
                  className="blockContent"
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              </VMBlock>
            )
          })}
      </div>
    </VMSection>
  )
}

export default VisionMissionBlocks
